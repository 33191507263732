<template>
  <div v-if="value && customer && vehicle"
    style="height: 87vh; overflow-y: auto;"
    class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Communications</p>
          <div class="tabs is-boxed">
            <ul>
              <li class="is-active">
                <a>
                  <span class="icon is-small">
                    <i class="mdi mdi-message-text mdi-20px"
                      aria-hidden="true" />
                  </span>
                  <span>SMS</span>
                </a>
              </li>
              <li v-show="false"
                class="">
                <a>
                  <span class="icon is-small">
                    <i class="mdi mdi-email mdi-20px"
                      aria-hidden="true" />
                  </span>
                  <span>Email</span>
                </a>
              </li>
            </ul>
          </div>
          <div ref="messageContainer"
            class="container has-background-light">
            <div v-for="(message) in messages"
              :key="message.id"
              class="is-flex px-1 py-2"
              :class="{ 'is-justify-content-flex-end': message.isIncoming }">
              <transition mode="out-in"
                name="custom-classes-transition"
                :enter-active-class="`animate__animated animate__slideInRight`"
                :leave-active-class="`animate__animated animate__slideInRight`">
                <article class="message is-flex"
                  :class="[{'is-info' : message.isOutgoing}, {'is-success message-reply': message.isIncoming}, {'unread-animation': !message.isRead}]">
                  <div class="message-body py-1 px-2">
                    <span v-if="message.isIncoming"
                      class="help is-italic">From: 0{{ message.sender }}</span>
                    <span v-if="message.isOutgoing && message.recipients"
                      class="help is-italic">To: {{ message.recipients }}</span>
                    <span class="message-sent">{{ message.message }}</span>
                    <div class="is-flex help is-italic"
                      :class="{ 'is-justify-content-flex-end': message.isIncoming }">
                      <span :class="[ {'is-info': message.isOutgoing}, {'is-success': message.isIncoming} ]">
                        {{ $filters.formatDateTimeLocale(message.createdDate, $userInfo.locale) }}
                      </span>
                    </div>
                  </div>
                </article>
              </transition>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="field">
            <label for=""
              class="label">Send To</label>
            <div class="control">
              <the-mask v-model="smsDetails.mobile"
                :mask="phoneNoMask"
                :tokens="phoneNoTokens"
                class="input"
                placeholder="Mobile No."
                v-focus-inserted />
            </div>
          </div>
          <div class="field">
            <label class="label">Message</label>
            <div class="control is-expanded">
              <multiselect v-model="selectedTemplate"
                track-by="message"
                label="message"
                placeholder="Select Template"
                :options="templates"
                :loading="isTemplateLoading"
                :searchable="true"
                :allow-empty="true"
                :options-limit="templateFilter.pageSize"
                @select="selectTemplate" />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <textarea v-model="smsDetails.message"
                class="textarea"
                rows="8"
                maxlength="2000"
                placeholder="Text message" />
            </div>
            <span class="help">{{ smsDetails.message.length }}/2000</span>
          </div>
          <div class="is-flex is-justify-content-space-between">
            <button class="button is-primary tooltip"
              data-tooltip="Edit message template"
              @click="isSmsTemplateModalActive = true">
              <span class="icon">
                <i class="mdi mdi-pencil mdi-20px" />
              </span>
              <span>Edit Template</span>
            </button>
            <button class="button is-success tooltip"
              @click="send()"
              data-tooltip="Send SMS"
              :disabled="!smsDetails.message || !smsDetails.mobile">
              <span class="icon">
                <i class="mdi mdi-message-text mdi-20px" />
              </span>
              <span>Send</span>
            </button>
          </div>
        </article>
      </div>
    </div>
    <sms-template-modal v-if="isSmsTemplateModalActive"
      :active.sync="isSmsTemplateModalActive"
      @close="closeSmsTemplateModal" />
  </div>
</template>

<script>
import { SmsSendMessageModel } from '@/classes/viewmodels'
import Multiselect from 'vue-multiselect'
import { FocusInserted } from '@/components/directives'
import { SmsService } from '@/services'
import { PhoneTypes } from '@/enums'
import SmsTemplateModal from '@/components/Sms/SmsTemplateModal'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'QuoteCommunicationsOLD',
  components: { Multiselect, SmsTemplateModal },
  directives: {
    FocusInserted
  },
  mixins: [],
  props: {
    value: {
      type: Object,
      default: null
    },
    customer: {
      type: Object,
      default: null
    },
    vehicle: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {
      phoneNoMask: Array(31).join('P'),
      phoneNoTokens: {
        P: {
          pattern: /[0-9, ()\-+]/
        }
      },
      smsDetails: new SmsSendMessageModel(),
      isTemplateLoading: false,
      templates: [],
      messages: [],
      selectedTemplate: '',
      templateFilter: {
        message: '',
        pageIndex: 1,
        pageSize: 999
      },
      isSmsTemplateModalActive: false,
      interval: null
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSmsMessageDropdown()
    this.autoRefresh()
    this.getMessages()
  },
  mounted() {
    if (this.customer) {
      const mobile = this.customer.phones.find((p) => p.type === PhoneTypes.Mobile.toString())
      this.smsDetails.mobile = mobile ? mobile.no : ''
    }
  },
  updated() {
    const unreadCount = this.messages.reduce((acc, cur) => (!cur.isRead ? ++acc : acc), 0)
    if (this.$refs.messageContainer && unreadCount) {
      this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    async getMessages() {
      this.messages = await SmsService.getMessages(this.value.quoteId)
      this.setMessagesAsRead()
    },
    async send() {
      this.$showSpinner('Sending...')
      const jobStart = new Date(this.value.jobStart).toLocaleDateString(this.$userInfo.locale)
      const jobEnd = new Date(this.value.jobEnd).toLocaleDateString(this.$userInfo.locale)

      this.smsDetails.quoteId = this.value.quoteId
      this.smsDetails.customer = this.customer.firstname ? this.customer.firstname : this.customer.fullname
      this.smsDetails.rego = this.vehicle.rego
      this.smsDetails.jobStart = jobStart
      this.smsDetails.jobEnd = jobEnd

      this.smsDetails.excess = this.value.excessWithGst
        ? this.value.excess + roundAwayFromZero((this.value.excess * this.value.gstRate) / 100)
        : this.value.excess
      try {
        await SmsService.sendSms(this.smsDetails)
        this.$notification.openNotificationWithType('success', 'Send SMS', 'Message Sent')
        this.smsDetails.message = ''
        this.selectedTemplate = ''
      } catch (e) {
        this.$notification.openMessageXhrError('Send SMS', e)
      } finally {
        this.getMessages()
        this.$hideSpinner()
      }
    },
    async getSmsMessageDropdown() {
      this.isTemplateLoading = true
      this.templates = await SmsService.getSmsMessageDropdownProto(this.templateFilter)
      if (this.templateFilter.message !== '') {
        this.selectedTemplate = this.templates.find((c) => c.message === this.templateFilter.message)
      }
      this.isTemplateLoading = false
    },
    selectTemplate(selected) {
      this.templateFilter.message = selected.message
      this.smsDetails.message = selected.message
    },
    async closeSmsTemplateModal() {
      this.isSmsTemplateModalActive = false
      await this.getSmsMessageDropdown()
    },
    autoRefresh() {
      this.interval = setInterval(async () => {
        await this.getMessages()
      }, 30000)
    },
    stopAutoRefresh() {
      clearInterval(this.interval)
    },
    setMessagesAsRead() {
      const unread = this.messages.filter((m) => !m.isRead)
      if (unread && unread.length > 0) {
        setTimeout(async () => {
          await SmsService.setMessagesAsRead(this.value.quoteId)
          this.messages.forEach((m) => (m.isRead = true))
        }, 10000)
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
article.message {
  width: 80%;
  &.message-reply {
    justify-content: end;
    > .message-body {
      text-align: right;
      border-width: 0 4px 0 0;
    }
  }
}
.message-body {
  width: 100%;
}
.message-sent {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
.container {
  max-height: calc(100vh - 18em);
  overflow-y: auto;
  scroll-behavior: smooth;
}
@keyframes highlight-unread {
  0% {
    filter: brightness(0.4);
    filter: contrast(0.4);
    -webkit-filter: brightness(0.4);
    -webkit-filter: contrast(0.4);
  }
  50% {
    filter: brightness(0.6);
    filter: contrast(0.6);
    -webkit-filter: brightness(0.6);
    -webkit-filter: contrast(0.6);
  }
  100% {
    filter: brightness(1);
    filter: contrast(1);
    -webkit-filter: brightness(1);
    -webkit-filter: contrast(1);
  }
}
.unread-animation {
  animation-name: highlight-unread;
  animation-duration: 10s;
  animation-iteration-count: 1;
  font-weight: bold;
}
</style>